<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-mb-4">
					<template #left>	
						<label class="p-text-bold p-mr-2" >Tipo</label>				
                        <Dropdown class="p-mr-2" v-model="selectedRecursoTipo" :options="recursosTipo" optionLabel="recurso_tipo" optionValue="id_recurso_tipo"/>						

						<label class="p-text-bold p-mr-2" >Recurso</label>				
                        <Dropdown class="p-mr-2" v-model="selectedRecurso" :options="recursos" optionLabel="recurso"/>
					</template>
				</Toolbar>                
				<FullCalendar :options="calendarOptions" ref="fullCalendar">
					<template v-slot:eventContent="arg">
						<div style="cursor:pointer;">
							<div>
								<div v-if="(arg.view.type=='dayGridMonth')" >
									<i v-if="arg.event.extendedProps.id_estado==10" class="pi pi-circle-on" style="color: #CC5500; font-size: .75em"/>
									<i v-if="arg.event.extendedProps.id_estado==20" class="pi pi-circle-on" style="color: #AEFD74; font-size: .75em"/>
									<i v-if="arg.event.extendedProps.id_estado==30" class="pi pi-circle-on" style="color: #E7EAE5; font-size: .75em"/>
									{{ arg.event.extendedProps.inmueble }}
								</div>
								<div v-else>
									<div style="color: black;">{{ arg.event.extendedProps.inmueble }}</div>
								</div>
							</div>
						</div>
					</template>
				</FullCalendar>
				<Dialog v-model:visible="eventDialog" :style="{width: '450px'}" header="Detalle de la reserva" :modal="true" :closable="true">
					<div class="p-fluid">
						<Toolbar class="p-mb-4">
							<template #left>
								<div  style="font-weight: bold;">
									{{ nombreRecurso }}
								</div>								
							</template>
							<template #right>	
								<Button v-if="(reserva.id_recurso_reserva != 0) && (!reserva.id_movimiento)" icon="pi pi-trash" class="p-mr-2" severity="warning" @click="confirmarCancelacionReserva"/>
								<Button v-if="reserva.id_recurso_reserva != 0" icon="pi pi-envelope" class="p-mr-2" severity="danger" @click="confirmarEnvioXEmail"/>							
							</template>
						</Toolbar>      						
						<!-- <div class="p-field">
							<Button v-if="reserva.id_recurso_reserva != 0" label="Cancelar Reserva" icon="pi pi-trash" class="p-button" severity="danger" @click="confirmarCancelacionReserva"/>
							<Button v-if="reserva.id_recurso_reserva != 0" label="Enviar Notificación" icon="pi pi-envelope" class="p-button" severity="danger" @click="confirmarEnvioXEmail"/>							
						</div> -->
						<div v-if="!reserva.id_movimiento" class="p-field">
							<label for="inmueble" style="font-weight: bold;">Inmueble</label>
							<div class="p-inputgroup">
								<InputText size="135" id="inmueble" type="text" v-model="reserva.inmueble" disabled/>																							
								<Button icon="pi pi-search" @click="seleccionarInmueble" />							
							</div>					
						</div>	
						<div v-else class="p-field" style="font-weight: bold;">
							<label for="start">Inmueble</label>
							<!-- <InputText id="title" v-model="reserva.contacto" required="true"/> -->
							<div style="font-weight: bold; color: green;">
								{{ reserva.inmueble }}									
							</div>							
						</div>													
						<div class="p-field" style="font-weight: bold;">
							<label for="start">Contacto</label>
							<!-- <InputText id="title" v-model="reserva.contacto" required="true"/> -->
							<div style="font-weight: bold; color: green;">
								{{ reserva.contacto }}									
							</div>							
						</div>													
						<div class="p-field" v-if="reserva.id_movimiento">
							<label for="id_movimiento" style="font-weight: bold;">Cargo emitido para esta reserva:</label>
							<span style="font-weight: bold; color: green;">&nbsp; [{{ reserva.no_docto }}]</span>
						</div>									
						<!-- <div class="p-field">
							<label for="Estado" style="font-weight: bold;">Estado</label>
							<Dropdown class="p-mr-2" v-model="reserva.id_reserva_estado" :options="estados" optionLabel="estado" optionValue="id_reserva_estado">	
								<template #option="slotProps">
									<div class="p-field">										
										<i v-if="slotProps.option.id_reserva_estado==10" class="pi pi-circle-on" style="color: #CC5500; font-size: .75em"></i>
										<i v-if="slotProps.option.id_reserva_estado==20" class="pi pi-circle-on" style="color: #AEFD74; font-size: .75em"></i>
										<i v-if="slotProps.option.id_reserva_estado==30" class="pi pi-circle-on" style="color: #E7EAE5; font-size: .75em"></i>										
										<span>&nbsp;&nbsp;{{ slotProps.option.estado }}</span>
									</div>
								</template>
							</Dropdown>								
						</div>						 -->
						<div class="p-field">
							<label for="start" style="font-weight: bold;">Desde</label>
							<Calendar id="start" v-model="reserva.desde" :showTime="true" :showIcon="!tieneCargoAsociado" :disabled="tieneCargoAsociado"/>
						</div>
						<div class="p-field">
							<label for="end" style="font-weight: bold;">Hasta</label>
							<Calendar id="end" v-model="reserva.hasta" :showTime="true" :showIcon="!tieneCargoAsociado" :disabled="tieneCargoAsociado"/>
						</div>
						<div class="p-field">
						<label for="Valor de Reserva" style="font-weight: bold;" v-if="reserva.auto_generar_mov||reserva.id_movimiento">Valor de Reserva</label>
							<div class="p-inputgroup" v-if="reserva.auto_generar_mov||reserva.id_movimiento">
								<InputNumber id="costo_reserva_privado" v-model="reserva.costo_reserva_privado" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :disabled="(reserva.id_movimiento != null)"/>
								<span class="p-inputgroup-addon">
									<Dropdown class="" v-model="reserva.id_moneda" :options="lkpMonedas" optionLabel="id_moneda" optionValue="id_moneda" :disabled="(reserva.id_movimiento != null)"/>									
								</span>
							</div>							
						</div>
						<!-- <div class="p-field" style="font-weight: bold;">
							<label for="end">email</label>
							<InputText id="title" v-model="reserva.email"/>
						</div>								 -->
						<div class="p-field">
							<label for="title" style="font-weight: bold;">Comentarios</label>
							<InputText id="title" v-model="reserva.comentarios" required="true" :disabled="tieneCargoAsociado"/>
						</div>																									
					</div>					
					<template #footer>
						<Button v-if="(reserva.id_movimiento_tipo) && (!reserva.id_movimiento)" label="Generar cargo por reserva" icon="pi pi-money-bill" class="p-button" severity="danger" @click="generarCargoXReserva"/>					
						<Button v-if="!tieneCargoAsociado" label="Guardar" icon="pi pi-check" class="p-button-text" @click="save" :disabled="!puedeGuardar"/>
						<Button label="Cerrar" icon="pi pi-refresh" class="p-button-text" @click="eventDialog=false" />
					</template>
				</Dialog>

				<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
				<ConfirmacionEnvioEmails :key="dlgConfirmarEmailsRefresh" mensaje="¿Desea enviar notificación de esta reserva a los siguientes destinatarios?" :destinatarios="emails" :mostrar="dlgEnvioEmail" @cancelar="cancelarEnvioXEmail"  @confirmacion="enviarEmail"/>

				<Dialog v-model:visible="confirmarCancelacionDlg" :modal="true">
					<template #header>
						Confirmación de cancelación de reserva
					</template>
					<div class="p-d-flex">
						<i class="pi pi-envelope p-mr-2" style="fontSize: 2rem"></i>
						<p>¿Está seguro que desea cancelar esta reserva?</p>
					</div>
					<template #footer>
						<div class="p-field p-col">							
							<Button label="Si" icon="pi pi-check" class="p-button-text" @click="cancelarReserva"/>
							<Button label="No" icon="pi pi-times" class="p-button-text" @click="confirmarCancelacionDlg=false"/>							
						</div>
					</template>
				</Dialog>					
			</div>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import MdsBuscarInmueble from '../../components/MdsBuscarInmueble.vue';
import ConfirmacionEnvioEmails from '../../components/ConfirmacionEnvioEmails.vue';
import MdsService from '../../service/mds.service';

export default {
	components: {
		Loading, MdsBuscarInmueble, ConfirmacionEnvioEmails
	},
	data() {
		return {
			loading: false,
			selectedRecursoTipo: null,
			selectedRecurso: null,
			estados: [],
			recursosTipo: [],
            recursos: [],
			emails: [],   
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,         
			eventDialog: false,
			clickedEvent: null,
			inmuebleDialog: false,	
			confirmarCancelacionDlg: false,
			dlgEnvioEmail: false,
			dlgConfirmarEmailsRefresh: 0,
			emails: [],			
			reserva: {
				id_recurso_reserva : 0,
				id_recurso: 0,
				id_reserva_estado: 0,
				id_inmueble: null,
				contacto: null,
				telefono: null,
				email: null,
				comentarios: null,
				costo_reserva_privado: null,
				id_moneda: null,
				id_movimiento_tipo: null,
				id_movimiento: null,
				creado_por: null,
				anulado: null,
				desde: null,
				hasta: null,
				fecha_creacion: null
			},
			calendarOptions: {	
				locales: [esLocale],
				locale: 'es',
				buttonText:    {
					today:    'Hoy',
					month:    'Mes',
					week:     'Semana',
					day:      'Dia',
					list:     'Lista'
                },
				plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
				initialView: 'dayGridMonth',		
				customButtons: {
					nuevaReservaButton: {
						text: 'Nueva Reserva',
						click: this.nuevaReserva
					}
				},						
				headerToolbar: {
					left: 'prev,next today',
					center: 'title',
					right: 'nuevaReservaButton dayGridMonth,timeGridWeek,timeGridDay'				
				},	
				editable: true,
				eventClick: (e) => {
					this.clickedEvent = e.event;
					this.eventToReserva(this.clickedEvent);						
					this.eventDialog = true;					
				},
				eventDrop: (e)=> {
					this.loading = true;
					try {					
						this.eventToReserva(e.event);
						this.guardarDB();						
						this.loading = false;
					} catch(err) {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: err})
						e.revert;												
					}
				},		
				eventResize: (e)=> {
					this.loading = true;
					try {
						this.eventToReserva(e.event);
						this.guardarDB();
						this.loading = false;
					} catch(err) {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: err})
						e.revert;												
					}
				},						
				events: null 
			},
		};
	},
	computed:
	{
		nombreRecurso() {			
			if(this.recursos.length > 0) {
				let r = this.recursos.find((val)=>{ return (val.id_recurso == this.selectedRecurso.id_recurso)});
				return r.recurso
			} else {
				return '';
			}
		},
		tieneCargoAsociado() {
			if(this.reserva) {
				if(this.reserva.id_movimiento) {
					return (this.reserva.id_movimiento != '');
				} else
				{
					return false;
				}
				
			} else {
				return false;
			}
		},
		puedeGuardar() {
			if(!this.reserva) {
				return false;
			}

			if(!this.reserva.id_inmueble) {
				return false;
			}

			if(!this.reserva.desde) {
				return false;
			}

			if(!this.reserva.hasta) {
				return false;
			}		
			
			return true;
		}		
	},
	async mounted() {
		this.estados = await MdsService.GetReservaEstados(this.$store.state.auth.currentAppCtx);
		await this.getRecursosTipo();
	},
	watch: {
		'selectedRecursoTipo':  function (newVal, oldVal) {
			this.getRecursos();
		},
		'selectedRecurso':  function (newVal, oldVal) {
			this.getReservas();
		}
	},
	methods: {		
		async getRecursosTipo() {
			this.loading = true;
			try {
				this.recursosTipo = [];
				var data = await MdsService.getRecursosReservaTipoDispo(this.$store.state.auth.currentAppCtx, this.$store.state.auth.currentAppCtx.complejo.id_complejo);
				this.recursosTipo = data;

				if(this.recursosTipo.length > 0) {
					var tipo = this.recursosTipo[0];
					this.selectedRecursoTipo = tipo.id_recurso_tipo;
				}
				this.loading = false;	
			} catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},	
		async getRecursos() {
			this.loading = true;			
			try {
				this.recursos = [];
				var data = await MdsService.getRecursosReserva(this.$store.state.auth.currentAppCtx, this.$store.state.auth.currentAppCtx.complejo.id_complejo, this.selectedRecursoTipo);
				this.recursos = data;

				if(this.recursos.length > 0) {
					var recurso = this.recursos[0];
					this.selectedRecurso = recurso;
				}
				this.loading = false;	
			} catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},				
		async getReservas() {
			this.loading = true;
			try {
				if(this.recursos.length == 0) {
					return;
				}

				var index = this.recursos.findIndex((val)=>{
					return (val.id_recurso == this.selectedRecurso.id_recurso);
				});
			
				var recurso = this.recursos[index];

				let eventData = await MdsService.getReservasXRecurso(this.$store.state.auth.currentAppCtx, recurso);

				this.calendarOptions.events = eventData.map((r)=> {
					return {
						id: r.id_recurso_reserva,
						title: r.comentarios,
						start: r.desde,
						end: r.hasta,
						color: r.estado_color,
						extendedProps: {
							inmueble: r.inmueble,
							id_estado: r.id_reserva_estado,
							estado: r.estado,	
							estado_color: r.estado_color,					
							id_inmueble: r.id_inmueble,
							id_recurso: r.id_recurso,
							desde: r.desde,
							hasta: r.hasta,
							auto_generar_mov: r.auto_generar_mov,
							costo_reserva_privado: r.costo_reserva_privado,
							id_moneda: r.id_moneda,
							id_movimiento_tipo: r.id_movimiento_tipo,
							id_movimiento: r.id_movimiento,
							no_docto: r.no_docto,
							contacto: r.contacto,
							telefono: r.telefono,
							email: r.email,
							costo_reserva_privado: r.costo_reserva_privado,
							comentarios: r.comentarios
						}					
					}
				});
				this.loading = false;
			} catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},		
		nuevaReserva(){
			let estado = this.estados.find((e)=>{ 
				return (e.id_reserva_estado == 10);
			});
			
			this.reserva = {
						id_recurso_reserva : 0,
						id_recurso: this.selectedRecurso.id_recurso,
						id_reserva_estado: estado.id_reserva_estado,
						estado_color: estado.color,
						id_inmueble: null,
						contacto: '',
						telefono: '',
						email: '',
						costo_reserva_privado: this.selectedRecurso.costo_reserva_privado,
						id_moneda: this.selectedRecurso.id_moneda,
						auto_generar_mov: this.selectedRecurso.auto_generar_mov,
						comentarios: '',
						id_movimiento_tipo: null,
						id_movimiento: null,
						creado_por: null,
						anulado: null,
						desde: new Date(),
						hasta: new Date(),
						fecha_creacion: null
					};				
			this.eventDialog = true;
		},
		cambioEnEvento(changeInfo){
			console.log(`changeInfo: ${JSON.stringify(changeInfo)}`);
		},		
		seleccionarInmueble() {
			this.inmuebleDialog = true;
		},		
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){
			this.inmuebleDialog = false;
			this.reserva.id_inmueble = Inmueble.id_inmueble;
			this.reserva.inmueble = Inmueble.display;
			this.reserva.contacto = Inmueble.nombre_documento;

			if(Inmueble.emails.length > 0){
				this.reserva.email = Inmueble.emails[0].email;
			}
		},		
		async guardarDB() {
			try {
				var data = await MdsService.RegistrarRecursoReserva(this.$store.state.auth.currentAppCtx, this.reserva);
				return data;
			} catch (err) {								 
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},
		async save() {
			try {
				this.eventDialog = false;
				this.loading = true;
				
				var data = await this.guardarDB();

				if(this.reserva.id_recurso_reserva == 0) {
					let nuevoEvento = {
						id: data.id,
						title: this.reserva.comentarios,
						start: this.reserva.desde,
						end: this.reserva.hasta,
						backgroundColor: this.reserva.estado_color,
						eventTextColor: '#000000',
						extendedProps: {
							id_inmueble: this.reserva.id_inmueble,
							inmueble: this.reserva.inmueble,
							id_estado: this.reserva.id_reserva_estado,
							estado_color: this.reserva.estado_color,
							id_recurso: this.reserva.id_recurso,
							id_movimiento_tipo: this.reserva.id_movimiento_tipo,
							costo_reserva_privado: this.reserva.costo_reserva_privado,
							id_moneda: this.reserva.id_moneda,
							id_movimiento: data.id_movimiento,
							no_docto: data.no_docto,
							contacto: this.reserva.contacto,
							telefono: this.reserva.telefono,
							email: this.reserva.email,
							comentarios: this.reserva.comentarios
						}
					};			

					this.calendarOptions.events = [
						...this.calendarOptions.events,
						nuevoEvento
					];
				} else {
					let calendar = this.$refs['fullCalendar'];
					let event = this.clickedEvent;

					if(event) {
						event.setProp('title', this.reserva.comentarios);
						event.setStart(this.reserva.desde);					
						event.setEnd(this.reserva.hasta);					
						event.setExtendedProp('id_inmueble', this.reserva.id_inmueble);
						event.setExtendedProp('inmueble', this.reserva.inmueble);
						event.setExtendedProp('desde', this.reserva.desde);
						event.setExtendedProp('hasta', this.reserva.hasta);												
						event.setExtendedProp('id_estado', this.reserva.id_reserva_estado);
						event.setExtendedProp('estado_color', this.reserva.estado_color);
						event.setExtendedProp('contacto', this.reserva.contacto);
						event.setExtendedProp('telefono', this.reserva.telefono);
						event.setExtendedProp('email', this.reserva.email);
						event.setExtendedProp('comentarios', this.reserva.comentarios);
						event.setExtendedProp('id_movimiento', data.id_movimiento);
						event.setExtendedProp('no_docto', data.no_docto);						
						event.setExtendedProp('costo_reserva_privado', this.reserva.costo_reserva_privado);
						event.setExtendedProp('id_moneda', this.reserva.id_moneda);
					} else {
						console.log(`El evento ${this.reserva.id_recurso_reserva} no fue encontrado!`);
					}
				}

				this.reserva = {};

				this.loading = false;				
			}  catch (err) {				 
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},
		confirmarCancelacionReserva() {
			this.confirmarCancelacionDlg = true;
		},
		async cancelarReserva() {
			try {
				this.eventDialog = false;
				this.confirmarCancelacionDlg = false;
				this.loading = true;
				
				var data = await MdsService.CancelarRecursoReserva(this.$store.state.auth.currentAppCtx, this.reserva);
				this.clickedEvent.remove();
				this.reserva = {};

				this.loading = false;				
			}  catch (err) {				 
				this.loading = false;
				this.confirmarCancelacionDlg = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},		
		async generarCargoXReserva() {
			try {
				this.loading = true;
				
				var data = await MdsService.GenerarCargoXReserva(this.$store.state.auth.currentAppCtx, this.reserva);
				
				this.reserva.id_movimiento = data.id_movimiento;
				this.reserva.no_docto = data.no_docto;				

				this.clickedEvent.setExtendedProp('id_movimiento', this.reserva.id_movimiento);
				this.clickedEvent.setExtendedProp('no_docto', this.reserva.no_docto);							

				this.loading = false;				
			}  catch (err) {		 
				this.loading = false;		
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},
		eventToReserva(e) {
			this.reserva.id_recurso_reserva = parseInt(e.id);
			this.reserva.id_recurso = e.extendedProps.id_recurso;
			this.reserva.id_reserva_estado = e.extendedProps.id_estado;
			this.reserva.id_inmueble = e.extendedProps.id_inmueble;		
			this.reserva.costo_reserva_privado = e.extendedProps.costo_reserva_privado;
			this.reserva.id_moneda = e.extendedProps.id_moneda;
			this.reserva.auto_generar_mov = e.extendedProps.auto_generar_mov;									
			this.reserva.contacto = e.extendedProps.contacto;					
			this.reserva.telefono = e.extendedProps.telefono;
			this.reserva.email = e.extendedProps.email;					
			this.reserva.comentarios = e.title;			

			this.reserva.estado = e.extendedProps.estado;
			this.reserva.estado_color = e.extendedProps.estado_color;
			this.reserva.desde = e.start;
			this.reserva.hasta = e.end?e.end:e.start;
	
			this.reserva.inmueble = e.extendedProps.inmueble;		
			this.reserva.id_movimiento_tipo	= e.extendedProps.id_movimiento_tipo;			
			this.reserva.id_movimiento = e.extendedProps.id_movimiento;	
			this.reserva.no_docto = e.extendedProps.no_docto;		
		},
		confirmarEnvioXEmail() {
			this.emails = [
				{
					email: this.reserva.email,
					asignado: true
				}];

			this.dlgConfirmarEmailsRefresh++;
			this.dlgEnvioEmail = true;			
		},
		async cancelarEnvioXEmail() {
			this.dlgEnvioEmail = false;
		},
		async enviarEmail(Emails) {
			this.dlgEnvioEmail = false;
			this.loading = true;
			try {				
				var notif = {						
					id: this.reserva.id_recurso_reserva,
					emails: Emails
				};

				await MdsService.enviarEmailReserva(this.$store.state.auth.currentAppCtx, notif);
				this.loading = false;
				
				this.dlgEnvioEmail = false;
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}			
		}		
	}
}
</script>

<style scoped>
::v-deep(.fc-header-toolbar) {
    display: flex;
    flex-wrap: wrap;
}
</style>
